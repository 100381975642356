import React from 'react';
import Layout from 'components/Layout';
import CrashedPage from 'features/CrashedPage';

const NotFoundPage = () => (
  <Layout transparentHeader={true} paddingTop={false} withOutBodyPadding={true}>
    <CrashedPage
      seo="404: Not found"
      title={
        <>
          OOPS! <br /> 404-PAGE NOT FOUND
        </>
      }
      buttonText="Go to Home Page"
      buttonPath="/"
      description={`<p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>`}
    />
  </Layout>
);

export default NotFoundPage;
